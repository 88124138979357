<template>
  <section class="customLogo-container">
    <div v-for="item in data" :key="item.id" class="customerLogo-card">
      <img :src="require('@/assets/img/customerLogo/' + item.src)" :alt="item.title" />
    </div>
  </section>
</template>

<script>
import customerLogo from "@/json/customerLogo.json";
export default {
  name: "Customer",
  data() {
    return {
      data: customerLogo
    };
  }
};
</script>
